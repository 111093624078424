<template>
  <div class="edit-table">
    <!-- 弹框 -->
    <SelectConfig ref="selectConfig" @onGetSelect="onGetSelect" />
    <vxe-button @click="addActivity" style="margin-bottom: 10px">活动类型</vxe-button>

    <div v-for="(item, k) in detailVos" :key="k" class="list-wrap">
      <el-form
        :model="detailForm"
        :rules="detailRule"
        ref="detailForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="活动大类" prop="name">
              <el-select
                v-model="item.categoriesCode"
                @change="getFineList($event,k)"
                placeholder="请选择活动大类"
              >
                <el-option
                  v-for="item in categoryList"
                  :key="item.categoriesCode"
                  :label="item.categoriesName"
                  :value="item.categoriesCode"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="活动细类" prop="name">
              <el-select v-model="item.fineCode" @change="changSubcategory($event,k)" placeholder="请选择活动细类">
                <el-option
                  v-for="sItem in item.subcategory"
                  :key="sItem.fineCode"
                  :label="sItem.fineName"
                  :value="sItem.fineCode"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8" style="display:flex; justify-content: flex-end;align-items: center;">
            <el-button type="danger" icon="el-icon-delete" circle size="mini" @click="deleteActivity(k)"></el-button>
          </el-col>
        </el-row>
        <vxe-button @click="addDetail(k,item)" style="margin-bottom: 10px"
          >新增明细</vxe-button
        >

        <el-tabs v-model="item.editableTabsValue"  type="card" closable @tab-remove="removeTab($event,k)">
          <el-tab-pane
            :key="act.tabValue"
            v-for="(act, j) in item.activityInfo"
            :label="'活动明细表单'+(j+1)"
            :name="act.tabValue"
          >
            <el-row>
              <el-col :span="8">
                <el-form-item label="关联预算" prop="feeBudgetCode">
                  <el-select
                    v-model="act.ext61"
                    placeholder="请选择关联预算"
                    @change="changeFeeBudget($event,k,j)"
                  >
                    <el-option
                      v-for="(item,bIndex) in budgetList"
                      :key="bIndex"
                      :label="item.feeBudgetCodes"
                      :value="item.feeBudgetCodes"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="公司主体" prop="ext3">
                  <el-input
                    disabled
                    v-model="act.ext3"
                    placeholder="公司主体"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="预算科目" prop="ext19">
                  <el-input
                    disabled
                    v-model="act.ext19"
                    placeholder="预算科目"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <!-- 活动明细信息 -->
            <el-row>
              <el-col :span="8">
                <el-form-item label="活动明细名称" prop="ext1">
                  <el-input
                    v-model="act.ext1"
                    placeholder="活动明细名称"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="活动明细编码" prop="actDetailCode">
                  <el-input
                    disabled
                    v-model="act.actDetailCode"
                    placeholder="活动明细编码"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="活动执行时间" prop="name">
                  <el-date-picker
                    v-model="act.executeTime"
                    @change="changeExecuteTime($event,k,j)"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    type="datetimerange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="受益经销商" prop="ext7">
                  <div  @click="openHandleClick('customer', 'customer_list', 'customerCode', k, j)">
                    <el-input v-model="act.ext7"  :disabled="act.ext4" placeholder="受益经销商"></el-input>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="经销商组织" prop="ext67">
                  <el-input v-model="act.ext67" disabled placeholder="经销商组织"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="受益组织" prop="ext5">
                  <div  @click="openHandleClick('org', 'org_list', 'orgCode', k, j)">
                    <el-input v-model="act.ext5"  :disabled="act.ext4" placeholder="受益组织"></el-input>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="责任组织" prop="createOrgName">
                  <el-input v-model="act.createOrgName" disabled placeholder="责任组织"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="负责人" prop="ext40">
                  <div  @click="openHandleClick('person', 'person_list', 'userName', k, j)">
                    <el-input v-model="act.ext40" placeholder="负责人"></el-input>
                  </div>
                </el-form-item>
              </el-col>
              <!-- 陈列模板显示 -->
              <el-col :span="8" v-if="item.ext68=='act-detail-template-2'">
                <el-form-item label="周期" prop="ext38">
                  <el-select
                    v-model="act.ext38"
                    placeholder="周期"
                  >
                    <el-option
                      v-for="pItem in cycleList"
                      :key="pItem.dictValue"
                      :label="pItem.dictKey"
                      :value="pItem.dictValue"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8" v-if="item.ext68=='act-detail-template-2'">
                <el-form-item label="产品" prop="ext5">
                  <div  @click="openHandleClick('displayProduct', 'product_list', 'materialCode', k, j)">
                    <el-input v-model="act.ext32" placeholder="产品"></el-input>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="8" v-if="item.ext68=='act-detail-template-2'">
                <el-form-item label="产品系列" prop="ext34">
                  <el-input v-model="act.ext34" disabled placeholder="产品系列"></el-input>
                </el-form-item>
              </el-col>
              <!-- 此处还差接口 -->
              <el-col :span="8" v-if="item.ext68=='act-detail-template-2'">
                <el-form-item label="阶梯" prop="ext35">
                  <el-select
                    v-model="act.ext35"
                    placeholder="阶梯"
                    filterable
                    remote
                    :remote-method="getDisplayLadder"
                  >
                    <el-option
                      v-for="pItem in diplayLadderList"
                      :key="pItem.ladderCode"
                      :label="pItem.ladderName"
                      :value="pItem.ladderCode"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8" v-if="item.ext68=='act-detail-template-2'">
                <el-form-item label="家数" prop="ext36">
                  <el-input v-model="act.ext36" placeholder="家数"></el-input>
                </el-form-item>
              </el-col>

              <!-- 包量模板内容 -->
              <el-col :span="8" v-if="item.ext68=='act-detail-template-3'">
                <el-form-item label="周期" prop="ext38">
                  <el-select
                    v-model="act.ext38"
                    placeholder="周期"
                  >
                    <el-option
                      v-for="pItem in cycleList"
                      :key="pItem.dictValue"
                      :label="pItem.dictKey"
                      :value="pItem.dictValue"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8" v-if="item.ext68=='act-detail-template-3'">
                <el-form-item label="产品" prop="ext5">
                  <div  @click="openHandleClick('displayProduct', 'product_list', 'materialCode', k, j)">
                    <el-input v-model="act.ext32" placeholder="产品"></el-input>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="8" v-if="item.ext68=='act-detail-template-3'">
                <el-form-item label="产品系列" prop="ext34">
                  <el-input v-model="act.ext34" placeholder="产品系列"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8" v-if="item.ext68=='act-detail-template-3'">
                <el-form-item label="阶梯" prop="ext35">
                  <el-select
                    v-model="act.ext35"
                    placeholder="阶梯"
                    filterable
                    remote
                    :remote-method="getPackageLadder"
                  >
                    <el-option
                      v-for="pItem in packageLadderList"
                      :key="pItem.ladderCode"
                      :label="pItem.ladderName"
                      :value="pItem.ladderCode"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8" v-if="item.ext68=='act-detail-template-3'">
                <el-form-item label="家数" prop="ext36">
                  <el-input v-model="act.ext36" placeholder="家数"></el-input>
                </el-form-item>
              </el-col>

              <el-col :span="8">
                <el-form-item label="执行次数" prop="ext37">
                  <el-input
                    v-model="act.ext37"
                    placeholder="执行次数"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="申请金额" prop="applyAmount">
                  <el-input
                    v-model="act.applyAmount"
                    placeholder="申请金额"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="支付方式" prop="payType">
                  <el-select
                    v-model="act.payType"
                    placeholder="请选择支付方式"
                    @change="changePayType($event,k,j,item.payTypes)"
                  >
                    <el-option
                      v-for="pItem in item.payTypes"
                      :key="pItem.dictValue"
                      :label="pItem.dictKey"
                      :value="pItem.dictValue"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <!-- 支付方式为货补的时候显示兑付产品 -->
              <el-col :span="8" v-if="act.payType=='4'">
                <el-form-item label="兑付产品"  prop="ext9">
                  <div @click="openHandleClick('product', 'product_list', 'materialCode', k, j)">
                    <el-input v-model="act.ext9" placeholder="兑付产品"></el-input>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="8" v-if="act.payType=='4'">
                <el-form-item label="单价" prop="ext21">
                  <el-input
                    v-model="act.ext21"
                    disabled
                    placeholder="单价"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="活动执行细则" prop="ext13">
                  <el-input
                    v-model="act.ext13"
                    type="textarea"
                    autosize
                    placeholder="活动执行细则"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>

          </el-tab-pane>
        </el-tabs>
      </el-form>
    </div>
  </div>
</template>

<script>
import request from '@/found/utils/request';
import SelectConfig from '@/found/components/select_org_cus_ter/select_config.vue';

export default {
  name: 'ContainOrg',
  props: {
    code: String,
    value: Array,
    disabled: Boolean,
    time: Object,
    controlIds: Array,
    saveRef: Function,
    budgetList: Array,
    default: () => ({
      controlIds: [],
      time: {},
      budgetList: [],
      value: [],
    }),
  },
  data() {
    return {
      diplayLadderList: [],
      packageLadderList: [],
      cycleList: [],
      index: '',
      aIndex: '',
      chooseModule: '',
      editableTabsValue: '',
      customerList: [],
      detailVos: [],
      detailForm: {},
      detailRule: {},
      subcategory: [],
      categoryList: [],
      selectRow: null,
      loading: false,
      beginDate: '',
      endDate: '',
      categoriesMapKeys: new Map(), // tab key信息
      tabName: '',
    };
  },
  components: {
    SelectConfig,
  },
  watch: {
    value() {
      this.detailVos = this.value;
      // this.categoriesMapKeys = new Map([...this.value.data]);
      // this.setDefaultTab();
    },
    time(n) {
      if (n) {
        this.beginDate = n.beginDate;
        this.endDate = n.endDate;
      }
    },
    // detailVos: {
    //   handler(newVal, oldVal) {
    //     // this.$emit('getBudgetValue', newVal);
    //   },
    //   deep: true,
    //   immediate: true,
    // },
  },
  mounted() {
    if (this.saveRef) {
      this.saveRef(this);
    }
  },
  created() {
    this.getCycleList();
    this.getPackageLadder();
    this.getDisplayLadder();
  },
  methods: {
    // 询价
    getPrice(cCode, pCode, index, aIndex) {
      if (!cCode || !pCode) return;
      request.post('/mdm/mdmPriceSettingExtController/searchAllPrice', {
        companyCode: cCode,
        priceTypeEnum: 'P001',
        productCode: pCode,
        num: 1,
      }).then((res) => {
        if (res.success) {
          const tempRes = res.result.itemResultVoList || [];
          this.detailVos[index].activityInfo[aIndex].ext21 = (tempRes && tempRes.length > 0) ? (tempRes[0].price || 0) : 0;
        }
      });
    },
    // 获取包量阶梯
    getPackageLadder(val) {
      request.get('/cps/v1/agreement/agreementLadder/findByConditions', {
        pageSize: 5000,
        ladderType: 'quantify',
        ladderName: val,
      }).then((res) => {
        if (res.success) {
          this.packageLadderList = res.result.records;
        }
      });
    },
    // 获取陈列阶梯
    getDisplayLadder(val) {
      request.get('/cps/v1/agreement/agreementLadder/findByConditions', {
        pageSize: 5000,
        ladderType: 'display',
        ladderName: val,
      }).then((res) => {
        if (res.success) {
          console.log('阶梯', res.result.records);
          this.diplayLadderList = res.result.records;
        }
      });
    },
    // 改变细类，根据选择的细类中的costFormCode，即表单名称，来判断引用哪个活动明细表单
    changSubcategory(val, index) {
      this.detailVos[index].activityInfo = [];
      if (val) {
        for (const item of this.detailVos[index].subcategory) {
          if (item.fineCode === val) {
            this.detailVos[index].ext68 = item.costFormCode;
            this.detailVos[index].payTypes = item.payTypes;
            this.detailVos[index].fineName = item.fineName;
          }
        }
      } else {
        this.detailVos[index].ext68 = null;
        this.detailVos[index].fineName = null;
        this.detailVos[index].fineName = null;
        this.detailVos[index].subcategory = [];
      }
      this.$emit('input', this.detailVos);
    },

    // 更改支付类型
    changePayType(val, index, aIndex, list) {
      this.detailVos[index].activityInfo[aIndex].ext9 = null;
      this.detailVos[index].activityInfo[aIndex].ext21 = null;
      if (val) {
        for (const item of list) {
          if (item.dictCode === val) {
            this.detailVos[index].activityInfo[aIndex].payTypeName = item.dictValue;
          }
        }
      } else {
        this.detailVos[index].activityInfo[aIndex].payTypeName = null;
      }
    },

    // 打开点击弹窗
    openHandleClick(module, list, key, index, aIndex) {
      if (this.code === 'view') {
        return;
      }
      this.index = index;
      this.aIndex = aIndex;
      this.chooseModule = module;
      const params = {
        functionCode: list,
        data: [],
        idKey: key,
      };
      this.$refs.selectConfig.openSelectModal(params);
    },

    // 获取周期
    getCycleList() {
      request.post('/mdm/mdmdictdata/list', {
        dictTypeCode: 'tpm_cycle_001',
      }).then((res) => {
        if (res.success) {
          this.cycleList = res.result;
        }
      });
    },

    // 获取弹窗选中数据
    onGetSelect(data) {
      const { index, aIndex } = this;
      if (this.chooseModule === 'org') {
        if (data.length > 0) {
          this.detailVos[index].activityInfo[aIndex].ext5 = data[0].orgName; // 受益组织名称
        } else {
          this.detailVos[index].activityInfo[aIndex].ext5 = null;
        }
        // 组织和经销商二选一，故选择了组织要清空经销商
        this.detailVos[index].activityInfo[aIndex].ext6 = null;
        this.detailVos[index].activityInfo[aIndex].ext7 = null;
        this.detailVos[index].activityInfo[aIndex].ext66 = null;
        this.detailVos[index].activityInfo[aIndex].ext67 = null;
      } else if (this.chooseModule === 'customer') {
        if (data.length > 0) {
          this.detailVos[index].activityInfo[aIndex].ext7 = data[0].customerName; // 受益经销商名称
          this.detailVos[index].activityInfo[aIndex].ext6 = data[0].customerCode; // 受益经销商编码
          this.detailVos[index].activityInfo[aIndex].ext66 = data[0].customerOrgCode;
          this.detailVos[index].activityInfo[aIndex].ext67 = data[0].customerOrgName;
        } else {
          this.detailVos[index].activityInfo[aIndex].ext7 = null;
          this.detailVos[index].activityInfo[aIndex].ext6 = null;
          this.detailVos[index].activityInfo[aIndex].ext66 = null;
          this.detailVos[index].activityInfo[aIndex].ext67 = null;
        }
        // 组织和经销商二选一，故选择了经销商要清空组织
        this.detailVos[index].activityInfo[aIndex].ext4 = null;
        this.detailVos[index].activityInfo[aIndex].ext5 = null;
      } else if (this.chooseModule === 'product') { // 兑付产品
        if (data.length > 0) {
          this.detailVos[index].activityInfo[aIndex].ext8 = data[0].materialCode;
          this.detailVos[index].activityInfo[aIndex].ext9 = data[0].materialName;
        } else {
          this.detailVos[index].activityInfo[aIndex].ext8 = null;
          this.detailVos[index].activityInfo[aIndex].ext9 = null;
        }
        this.getPrice(this.detailVos[index].activityInfo[aIndex].ext2, this.detailVos[index].activityInfo[aIndex].ext8, index, aIndex);
      } else if (this.chooseModule === 'person') {
        if (data.length > 0) {
          this.detailVos[index].activityInfo[aIndex].ext40 = data[0].fullName;
          this.detailVos[index].activityInfo[aIndex].ext39 = data[0].userName;
          this.detailVos[index].activityInfo[aIndex].ext69 = data[0].positionCode;
          this.detailVos[index].activityInfo[aIndex].ext70 = data[0].positionName;
        } else {
          this.detailVos[index].activityInfo[aIndex].ext40 = null;
          this.detailVos[index].activityInfo[aIndex].ext39 = null;
          this.detailVos[index].activityInfo[aIndex].ext69 = null;
          this.detailVos[index].activityInfo[aIndex].ext70 = null;
        }
      } else if (this.chooseModule === 'displayProduct') { // 陈列或包量产品
        if (data.length > 0) {
          this.detailVos[index].activityInfo[aIndex].ext31 = data[0].materialCode;
          this.detailVos[index].activityInfo[aIndex].ext32 = data[0].materialName;
          this.detailVos[index].activityInfo[aIndex].ext33 = data[0].productLevelCode;
          this.detailVos[index].activityInfo[aIndex].ext34 = data[0].productLevelName;
        } else {
          this.detailVos[index].activityInfo[aIndex].ext31 = null;
          this.detailVos[index].activityInfo[aIndex].ext32 = null;
          this.detailVos[index].activityInfo[aIndex].ext33 = null;
          this.detailVos[index].activityInfo[aIndex].ext34 = null;
        }
      }
    },

    // 改变关联预算
    changeFeeBudget(val, aIndex, dIndex) {
      if (val) {
        for (const item of this.budgetList) {
          if (item.feeBudgetCodes === val) {
            this.detailVos[aIndex].activityInfo[dIndex].ext3 = item.ext8; // 公司主体名称
            this.detailVos[aIndex].activityInfo[dIndex].ext2 = item.ext7; // 公司主体编码
            this.detailVos[aIndex].activityInfo[dIndex].budgetSubjectsCode = item.budgetSubjectsCode; // 预算科目编码
            this.detailVos[aIndex].activityInfo[dIndex].ext19 = item.budgetSubjectsName; // 预算科目名称
            this.detailVos[aIndex].activityInfo[dIndex].orgCode = item.orgCode; // 预算科目名称
            this.detailVos[aIndex].activityInfo[dIndex].orgName = item.orgName; // 预算科目名称
          }
        }
      } else {
        this.detailVos[aIndex].activityInfo[dIndex].ext61 = null;
        this.detailVos[aIndex].activityInfo[dIndex].ext62 = null;
        this.detailVos[aIndex].activityInfo[dIndex].ext3 = null;
        this.detailVos[aIndex].activityInfo[dIndex].ext2 = null;
        this.detailVos[aIndex].activityInfo[dIndex].budgetSubjectsCode = null;
        this.detailVos[aIndex].activityInfo[dIndex].ext19 = null;
      }
      this.getPrice(this.detailVos[aIndex].activityInfo[dIndex].ext2, this.detailVos[aIndex].activityInfo[dIndex].ext8, aIndex, dIndex);
      this.$emit('input', this.detailVos);
    },

    // 更改执行时间
    changeExecuteTime(val, index, aIndex) {
      if (val) {
        const [startTime, endTime] = val;
        const [startDate, startSeconde] = startTime.split(' ');
        const [endDate, endSeconde] = endTime.split(' ');
        this.detailVos[index].activityInfo[aIndex].executeBeginDate = startDate;
        this.detailVos[index].activityInfo[aIndex].executeBeginDateSecond = startSeconde;
        this.detailVos[index].activityInfo[aIndex].executeEndDate = endDate;
        this.detailVos[index].activityInfo[aIndex].executeEndDateSecond = endSeconde;
      } else {
        this.detailVos[index].activityInfo[aIndex].executeBeginDate = null;
        this.detailVos[index].activityInfo[aIndex].executeBeginDateSecond = null;
        this.detailVos[index].activityInfo[aIndex].executeEndDate = null;
        this.detailVos[index].activityInfo[aIndex].executeEndDateSecond = null;
      }
    },

    // 删除活动
    deleteActivity(index) {
      this.detailVos.splice(index, 1);
      this.$emit('input', this.detailVos);
    },

    // 新增活动类型
    addActivity() {
      this.detailVos.push({
        categoriesName: '',
        categoriesCode: '',
        fineCode: '',
        fineName: '',
        activityInfo: [],
        ext68: null, // 表单号
        payTypes: [],
        subcategory: [],
        editableTabsValue: null, // 激活的选项
      });
      this.$emit('input', this.detailVos);
    },

    // 新增明细
    addDetail(index, item) {
      // if (item.fineCode) {
      const userInfo = JSON.parse(localStorage.getItem('userInfo'));
      this.detailVos[index].editableTabsValue = `activity${index}-1`;
      this.detailVos[index].activityInfo.push({
        orgCode: null,
        orgName: null,
        feeBudgetCode: null,
        feeBudgetTypeName: null,
        ext3: null, // 公司主体名称
        ext2: null, // 公司主体编码
        budgetSubjectsCode: null,
        ext19: null,
        actDetailCode: null,
        ext1: null, // 活动明细名称
        executeBeginDate: null,
        executeBeginDateSecond: null,
        executeEndDate: null,
        executeEndDateSecond: null,
        executeTime: null,
        ext6: null, // 受益经销商编码
        ext7: null, // 受益经销商名称
        ext66: null, // 经销商组织编码
        ext67: null, // 经销商组织名称
        ext4: null, // 受益组织编码
        ext5: null, // 受益组织名称
        createOrgCode: userInfo.orgCode || null, // 责任组织编码
        createOrgName: userInfo.orgName || null, // 责任组织名称
        ext40: null, // 责任人名称
        ext39: null, // 责任人编码
        ext69: null, // 职位编码
        ext70: null, // 职位名称
        applyAmount: null, // 申请金额
        ext8: null, // 兑付产品编码
        ext9: null, // 兑付产品名称
        ext21: null, // 单价
        payType: null, // 支付方式
        ext37: null, // 执行次数
        ext38: null, // 陈列周期
        ext31: null, // 陈列产品编码
        ext32: null, // 产品名称
        ext33: null, // 产品系列编码
        ext34: null, // 产品系列描述
        ext35: null, // 阶梯
        ext36: null, // 家数
      });
      /* eslint-disable */
      this.detailVos[index].activityInfo.map((aItem, a) => {
        aItem.tabValue = `activity${index}-${a+1}`;
      });
      // } else {
      //   this.$message.warning('请先选择活动细类');
      // }
      this.$emit('input', this.detailVos);
    },

    // 获取细类列表
    async getFineList(val, index) {
      this.categoryList.forEach((cc) => {
        if (cc.categoriesCode === val) {
          this.detailVos[index].categoriesName = cc.categoriesName;
        }
      });
      this.detailVos[index].fineCode = null;
      this.detailVos[index].fineName = null;
      this.detailVos[index].activityInfo = [];
      if (val) {
        const param = {
          categoriesCode: val,
          actType: 'project',
        };
        const res = await request.post(
          '/tpm/tpmActController/getCategoryFinesByCategoryCode',
          param,
        );
        this.detailVos[index].subcategory = res.result || [];
      } else {
        this.detailVos[index].subcategory = [];
      }
      this.$emit('input', this.detailVos);
    },

    // 保留已选大类
    async reset(ids) {
      if (ids && ids.length) {
        const data = await this.getCateGoriesData(ids);
        // const list = this.categoriesMapKeys;
        // let newCodes = [];
        // if (data && data.length) {
        //   newCodes = data.map((item) => item.categoriesCode); // 获取最新code
        //   // 删除已经移除的code
        //   for (const key of list.keys()) {
        //     if (!newCodes.includes(key)) {
        //       list.delete(key);
        //     }
        //   }
        // }
        // this.categoriesMapKeys = list;
        // this.setDefaultTab();
      } else {
        this.clear();
      }
    },


    // 清空数据
    clear(){
      this.detailVos = [];
       this.$emit('input', this.detailVos);
    },

    // 移出tabs
    removeTab(targetName, index) {
       this.$confirm('确定要删除吗, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
         let tabs =  this.detailVos[index].activityInfo;
            let activeName = this.detailVos[index].editableTabsValue;
            if (activeName === targetName) {
              tabs.forEach((tab, index) => {
                if (tab.tabValue === targetName) {
                  let nextTab = tabs[index + 1] || tabs[index - 1];
                  if (nextTab) {
                    activeName = nextTab.tabValue;
                  }
                }
              });
            }
            this.detailVos[index].activityInfo = tabs.filter(tab => tab.tabValue !== targetName);
            if(this.detailVos[index].activityInfo.length===1){
                this.detailVos[index].editableTabsValue = this.detailVos[index].activityInfo[0].tabValue
            }else{
              this.detailVos[index].editableTabsValue = activeName;
            }
            this.$emit('input', this.detailVos);
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
    },


    // 新增预算信息
    addTab() {
      const { beginDate, endDate } = this.time;
      if (this.time && (!beginDate || !endDate)) {
        this.$message.error('请先选择活动时间！');
      } else if (
        !this.controlIds
        || (this.controlIds && this.controlIds.length === 0)
      ) {
        this.$message.error('请先选择预算费用！');
      } else {
        const _data = Array.from(this.categoriesMapKeys.keys()).map((v) => ({
          categoriesCode: v,
        }));
        const params = {
          functionCode: 'tpm_project_categories_list',
          data: _data,
          idKey: 'categoriesCode',
          paramData: {
            enableStatus: '009',
            controlIds: this.controlIds,
            actType: 'project',
          },
        };
        this.$refs.selectConfig.openSelectModal(params);
      }
    },


    // 获取管理的活动大类
    async getCateGoriesData(ids) {
      const res = await request.post(
        '/tpm/tpmActController/getCategoriesByBudget',
        {
          enableStatus: '009',
          controlIds: ids,
          actType: 'project',
        },
      );
      this.categoryList = res.result;
      return res.result || [];
    },
  },
};
</script>

<style lang="less" scoped>
.edit-table {
  padding-left: 50px;
}
.list-wrap {
  border-bottom: 1px solid #f1f1f1;
  margin-bottom: 20px;

}
</style>
