import BasePage from '../../../../../../components/table_page';
import { ProcessLog } from '../../../../../../modules';
import Form from '../form';
import request from '../../../../../../utils/request';
import Reconsideration from '../components/reconsideration';
import submitApprove from '../components/submit_approve';

export default {
  name: 'marketing_activity_list',
  extends: BasePage,
  data() {
    return {
      params: {
        actType: 'project',
        approveType: 'Y',
      },
    };
  },
  components: {
    Form,
    ProcessLog,
    Reconsideration,
    submitApprove,
  },
  created() {
    this.getConfigList('marketing_reconsideration_activity');
  },
  methods: {
    // 控制按钮显示
    clickVisible({ row, val: { code } }) {
      // 审批通过 approved,审批中 approving,活动关闭 closed,流程追回 interrupt,驳回 rejected
      const { approveStatus, processCode } = row;
      if (code === 'process_log' && !processCode) {
        return false;
      }
      if (approveStatus && code === 'submit_approval' && approveStatus !== 'rejected' && approveStatus !== 'create') {
        return false;
      }
      if (approveStatus && code === 'edit' && approveStatus !== 'rejected' && approveStatus !== 'create') {
        return false;
      }
      return true;
    },
    // 活动关闭前置
    beforeButtonClick({ val }) {
      let state = true;
      if (val.code === 'activities_close' && this.selectRow.length === 0) {
        this.$message('最少选择一条数据操作');
        state = false;
      }
      return state;
    },
    modalClick({ val, row }) {
      const { code } = val;
      if (code === 'view' || code === 'edit') {
        request.post('/activiti/taActBaseController/getSubmitActiviti', {}).then((res) => {
          if (res.success) {
            const tempArr = [];
            const tempList = res.result.details ? res.result.details : [];
            for (const item of tempList) {
              tempArr.push({
                label: item.processName,
                value: item.processKey,
              });
            }

            this.formName = 'Reconsideration';
            this.formConfig = {
              ...this.formConfig,
              code,
              row,
              progressList: tempArr,
            };
            this.modalConfig.title = code === 'edit' ? '编辑' : '查看';
            this.openFull();
          }
        });
      } else if (code === 'process_log') {
        this.formConfig = {
          ...this.formConfig,
          code,
          row: { processNo: row.processCode },
        };

        this.modalConfig.title = '流程日志';
        this.formName = 'ProcessLog';
        this.openDrawer();
      } else if (code === 'submit_approval') { // 市场活动复议
        request.post('/activiti/taActBaseController/getSubmitActiviti', {}).then((res) => {
          if (res.success) {
            const tempArr = [];
            const tempList = res.result.details ? res.result.details : [];
            for (const item of tempList) {
              tempArr.push({
                label: item.processName,
                value: item.processKey,
              });
            }
            this.formConfig = {
              ...this.formConfig,
              code,
              row,
              progressList: tempArr,
            };
            this.modalConfig.title = '活动复议';
            this.formName = 'submitApprove';
            this.openDrawer();
          }
        });
      }
    },
    // 提交审批回调
    onSubmit(params) {
      const configParams = {
        ...params,
        code: 'approve',
      };

      this.$emit('approve', configParams);
    },
  },
};
