import Form, { formCreate } from '@/found/components/form';
import request from '@/found/utils/request';
import JudgeTime from '../utils';
import RelationFineGroup, { BudgetSubjectTable } from '../components';

formCreate.component('RelationFine', RelationFineGroup);
formCreate.component('BudgetSubjectForm', BudgetSubjectTable);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      isControl: null,
      // formFunctionCode: 'activity_close_form',
      // formParentCode: 'CRM20211012000002743',
      actType: 'project',
      rule: [],
      pickerOptions: {
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            picker.$emit('pick', new Date());
          },
        }, {
          text: '昨天',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', date);
          },
        }, {
          text: '一周前',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', date);
          },
        }],
      },
      fData: {},
      buttonText: {
        submit: '保存',
      },
      buttons: {
        submitAudit: this.formConfig.code !== 'view',
      },
    };
  },

  async created() {
    this.isControl = await JudgeTime.getControlType();
    const { initData, row } = this.formConfig;
    if (initData) {
      this.$nextTick(() => {
        this.initData();
      });
    } else if (row && row.id) {
      this.init();
    }
    this.getControlConfig();
  },
  methods: {
    // 设置rule
    setRule(item) {
      const v = item;
      if (v.field === 'actTime') { // 活动时间
        v.props = {
          ...v.props,
          type: 'datetimerange',
          pickerOptions: this.pickerOptions,
          rangeSeparator: '至',
          startPlaceholder: '开始日期',
          endPlaceholder: '结束日期',
          unlinkPanels: true,
          align: 'right',
          valueFormat: 'yyyy-MM-dd HH:mm:ss',
          defaultTime: ['00:00:00', '23:59:59'],
        };

        v.on = {
          change: this.onActTimeChange,
        };
      }

      if (v.field === 'budget') {
        v.props = {
          ...v.props,
          saveRef: this.saveBudgetRef,
          startTime: '',
          endTime: '',
          isControl: this.isControl !== 'no_control',
        };
        v.value = {
          data: [],
        };
        v.on = {
          ...v.on,
          change: this.onBudgetCode,
          clear: this.clearBudgetCode,
          deleteAssociate: this.deleteAssociate,
        };
      }

      if (v.field === 'relationFine') {
        v.props = {
          ...v.props,
          time: {},
          controlIds: [],
          saveRef: this.saveRef,
        };
        v.validate = [
          {
            trigger: 'change',
            required: true,
            message: '请填写明细信息！',
          },
        ];
      }
      return v;
    },
    // 获取管控配置
    getControlConfig() {
      if (!this.judgeTime) {
        this.judgeTime = new JudgeTime(this.formConfig.code !== 'add' ? {
          time: [
            `${this.formConfig.row.beginDate} ${this.formConfig.row.beginDateSecond}`,
            `${this.formConfig.row.endDate} ${this.formConfig.row.endDateSecond}`,
          ],
          model: this.getFormData(),
        } : null);
      }
    },
    saveBudgetRef(ref) {
      this.budgetRef = ref;
    },

    saveRef(ref) {
      this.relationFineRef = ref;
    },

    // 获取大类数据
    async getSelectOptions(url, params) {
      const res = await request.post(url, params);
      return res.result.data || res.result || [];
    },

    // 获取活动详情
    async getBudgetValue(val) {
      const relationFine = this.getRule('relationFine');
      relationFine.value = {
        data: val,
      };
    },

    // 删除关联信息
    async deleteAssociate(row) {
      const relationFine = this.getRule('relationFine');
      if (row) {
        const res = await request.post('/tpm/tpmActController/getCategoriesByBudget', {
          enableStatus: '009',
          controlIds: [row.id],
          actType: 'project',
        });
        console.log('关联的活动大类', res.result);
        const detailData = relationFine.value;
        if (res.result.length > 0) {
          // 判断是否有关联的活动大类，有，删除关联的活动大类信息
          res.result.forEach((item, k) => {
            detailData.forEach((dd, j) => {
              if (item.categoriesCode === dd.categoriesCode) {
                detailData.splice(j, 1);
              }
            });
          });
        } else {
          detailData.forEach((detail, b) => {
            if (row.feeBudgetCodes === detail.ext61) {
              detailData[k].activityInfo[j].splice(b, 1);
            }
          });
        }

        relationFine.value = detailData;
      }

      // relationFine.props = {
      //   ...relationFine.props,
      //   categoriesCode: val,
      //   categoriesName,
      // };
    },

    // 监听大类
    onCategoriesCode(val, data) {
      let categoriesName = '';
      const index = data.findIndex((item) => item.value === val);
      if (index > -1) {
        categoriesName = data[index].label;
      }
      if (val) {
        const relationFine = this.getRule('relationFine');
        relationFine.props = {
          ...relationFine.props,
          categoriesCode: val,
          categoriesName,
        };
      }
    },
    // 监听预算科目
    onBudgetCode(row) {
      console.log('监听到预算科目改变', row);
      let ids = [];
      if (row && row.length) {
        ids = row.map((item) => item.id);
      }
      const relationFine = this.getRule('relationFine');
      relationFine.props = {
        ...relationFine.props,
        controlIds: ids,
        budgetList: row,
      };

      if (this.relationFineRef) {
        this.relationFineRef.reset(ids);
      }
    },

    // 监听清空预算科目
    clearBudgetCode() {
      const relationFine = this.getRule('relationFine');
      relationFine.props = {
        ...relationFine.props,
        controlIds: [],
        budgetList: [],
      };

      if (this.relationFineRef) {
        this.relationFineRef.clear();
      }
    },
    // 时间格式化
    getTime(startTime, endTime) {
      return {
        startTime,
        endTime,
        beginDate: startTime.indexOf(' ') ? startTime.split(' ')[0] : '',
        beginDateSecond: startTime.indexOf(' ') ? startTime.split(' ')[1] : '',
        endDate: endTime.indexOf(' ') ? endTime.split(' ')[0] : '',
        endDateSecond: endTime.indexOf(' ') ? endTime.split(' ')[1] : '',
      };
    },
    // 格式化
    formateTime(val) {
      let time = null;
      if (val && Array.isArray(val) && val.length === 2) {
        const {
          beginDate, beginDateSecond, endDate, endDateSecond,
        } = this.getTime(val[0], val[1]);
        time = {
          beginDate, beginDateSecond, endDate, endDateSecond,
        };
      }
      return time;
    },

    // 改变活动时间
    onActTimeChange(val) {
      if (val && Array.isArray(val) && val.length === 2) {
        const startTime = val[0];
        const endTime = val[1];
        const budgetCode = this.getRule('budget');
        budgetCode.props = {
          ...budgetCode.props,
          startTime,
          endTime,
        };

        // 时间发生变化时，比较差异。删除不在活动时间内的预算
        if (this.budgetRef) {
          this.budgetRef.reset(startTime, endTime);
        }

        const relationFine = this.getRule('relationFine');
        relationFine.props = {
          ...relationFine.props,
          time: this.getTime(startTime, endTime),
        };
      } else {
        const budgetCode = this.getRule('budget');
        budgetCode.props = {
          ...budgetCode.props,
          startTime: '',
          endTime: '',
        };
      }
    },
    /**
     * params:
     *  data: 数组
     *  keyValue: 原key
     *  replaces: 替换key
     *  otherProps: 其他参数
     *  direction:  正向
     *  isId: 是否需要id
      */
    formateData(data, keyValues, replaces, otherProps = {}, direction = true, isId = true) {
      const tmp = [];
      if (!data) return tmp;
      if (data && data.length === 0) return tmp;

      data.forEach((item) => {
        let obj = {
          ...otherProps,
        };

        if (item.id && !isId) {
          obj.id = item.id;
        }

        if (direction && replaces.length && keyValues.length) {
          replaces.forEach((key, index) => {
            if (item[keyValues[index]]) {
              obj[key] = item[keyValues[index]];
            }
          });
        } else if (!direction && replaces.length && keyValues.length) {
          keyValues.forEach((key, index) => {
            if (item[replaces[index]]) {
              obj[key] = item[replaces[index]];
            }
          });
        } else {
          const { id, ...otherItem } = item;
          obj = { ...otherItem, ...otherProps };
        }
        tmp.push(obj);
      });
      return tmp;
    },

    // 处理明细信息
    async formateDetail(data) {
      const detailVos = [];
      data.forEach((item) => {
        item.activityInfo.forEach((act) => {
          detailVos.push({
            feeDateStr: act.executeBeginDate,
            categoriesName: item.categoriesName,
            categoriesCode: item.categoriesCode,
            fineCode: item.fineCode,
            fineName: item.fineName,
            ext68: item.ext68, // 表单号
            ...act,
          });
        });
      });
      return detailVos;
    },

    // 获取明细map
    getDetail(data) {
      const { detailVos, categoriesFeeShareMap, feeShareVos } = data;
      const detail = new Map();
      if (detailVos && detailVos.length) {
        detailVos.forEach((subItem) => {
          const item = subItem;
          console.log(item);
          item.giftCodes = (item.giftProductList || []).map((v) => v.productCode);
          item.normalProductCodes = (item.normalProductList || []).map((v) => v.productCode);
          item.replenishmentProductCodes = (item.replenishmentProductList || []).map((v) => v.productCode);
          item.executionProductCodes = (item.executionProductList || []).map((v) => v.productCode);

          const code = item.categoriesCode;
          let _procuct = [];
          let isShowProduct = false;
          if (categoriesFeeShareMap && categoriesFeeShareMap[code]) {
            _procuct = categoriesFeeShareMap[code] || [];
            // 处理分摊大类
            if (_procuct && _procuct.length) {
              // eslint-disable-next-line no-shadow
              _procuct.forEach((item, index) => {
                _procuct[index].categoriesCode = code;
              });
            }
          } else if (feeShareVos && feeShareVos.length) {
            _procuct = feeShareVos.filter((item1) => item1.categoriesCode === code);
          }

          isShowProduct = _procuct.length >= 0;

          if (detail.has(code)) {
            const obj = detail.get(code);
            obj.fine.data.push(item);
            detail.set(code, obj);
          } else if (code !== '') {
            detail.set(code, {
              label: item.categoriesName,
              value: item.categoriesCode,
              functionCode: item.formCode,
              fine: { data: [item] },
              showProduct: isShowProduct,
              product: { data: _procuct },
            });
          }
        });
      }
      return detail;
    },
    async formateAmount(data, formData) {
      const tmp = data.filter((item) => typeof item.actDetailCode === 'undefined');
      let codes = [];
      // 请求明细编码
      if (tmp.length) {
        const res = await request.get('/tpm/tpmActController/generateActDetailCodes', { num: tmp.length });
        if (res.success) {
          codes = res.result;
        }
      }
      // 处理数据
      const list = data || [];
      const {
        beginDate, beginDateSecond, endDate, endDateSecond,
      } = formData;

      let n = 0;
      list.forEach((item, index) => {
        const params = {
          ...item,
          executeBeginDate: beginDate,
          executeBeginDateSecond: beginDateSecond,
          executeEndDate: endDate,
          executeEndDateSecond: endDateSecond,
        };
        // 明细编码
        if (typeof item.actDetailCode === 'undefined') {
          params.actDetailCode = codes[n];
          n += 1;
        }
        // 分摊日期
        if (params.feeShareGroupId === '') {
          params.feeDateStr = beginDate.substr(0, 7);
        }

        delete params._XID;

        list[index] = params;
      });

      return list;
    },
    formateProduct(products, detail) {
      const tmp = [];
      if (detail && detail.length) {
        const shareToProduct = detail.filter((item) => item.isShareToProduct === 'Y');
        if (shareToProduct && products) {
          shareToProduct.forEach((item) => {
            products.forEach((product) => {
              if (product.categoriesCode !== '' && item.categoriesCode === product.categoriesCode) {
                tmp.push({
                  ...product,
                  shareRatio: Number(product.shareRatio),
                  actDetailCode: item.actDetailCode,
                  shareType: 'product',
                });
              }
            });
          });
        }
      }
      return tmp;
    },
    // 根据费用预算类型-设置reduceOrder
    formateBudget(data) {
      const budget = new Map();
      const tmp = [];
      if (data && data.length) {
        data.forEach((item) => {
          const ids = item.feeBudgetType + item.orgType;
          if (budget.has(ids)) {
            const values = budget.get(ids);
            values.push(item);
            budget.set(ids, values);
          } else {
            budget.set(ids, [item]);
          }
        });

        if (budget.size) {
          let index = 0;
          budget.forEach((value) => {
            index += 1;
            value.forEach((feeBudget) => {
              const obj = feeBudget;
              obj.feeBudgetVos[0].reduceOrder = index;
              tmp.push({
                ...obj,
              });
            });
          });
        }
      }
      return tmp;
    },
    // 提交
    async submit(type) {
      let params = this.getFormData();
      console.log('提交数据', params);
      if (!params) return false;

      let url = '/tpm/tpmActController/save';

      const { row, code } = this.formConfig;
      const { actTime, budget, relationFine } = params;

      if (!actTime) return false;

      if (budget.data && budget.data.length === 0) {
        this.$message({
          type: 'error',
          message: '请选择预算!',
        });
        return false;
      }

      if (relationFine.data && relationFine.data.length === 0) {
        this.$message({
          type: 'error',
          message: '请填写活动明细',
        });
        return false;
      }

      params.actType = this.actType;
      // 时间处理
      const time = this.formateTime(actTime);
      if (time) {
        params = { ...params, ...time };
      }

      // 费用预算处理
      if (budget.data && budget.data.length) {
        const feeBudgetVos = this.formateBudget(budget.data);
        params.budgetControlVos = feeBudgetVos;
      }

      // 处理明细
      params.detailVos = await this.formateDetail(relationFine);

      // 文件处理
      params.ext11 = JSON.stringify(params.ext11 || []);

      delete params.budget;
      delete params.relationFine;
      delete params.actTime;
      if (code === 'edit') {
        url = '/tpm/tpmActController/update';
        params = { ...params, id: row.actId || row.id, actCode: row.actCode };
        if (type === 1) {
          params.saveType = 3;
        } else {
          params.saveType = 4;
        }
      } else {
        params.saveType = type;
      }

      // 跳转下一步
      if (type === 2) {
        this.$emit('submit', {
          row: params,
          submitUrl: url,
        });
      } else {
        const { success } = await request.post(url, params);
        if (success) {
          this.$message({
            type: 'success',
            message: '保存成功',
          });
          this.$emit('onClose');
          this.$emit('onGetList');
        }
      }
    },

    initData() {
      const { initData } = this.formConfig;
      // 处理时间
      initData.actTime = [`${initData.beginDate} ${initData.beginDateSecond}`, `${initData.endDate} ${initData.endDateSecond}`];
      // 费用预算编码
      initData.budget = {
        data: initData.budgetControlVos,
      };

      const budgetCode = this.getRule('budget');
      budgetCode.props = {
        ...budgetCode.props,
        startTime: initData.beginDate,
        endTime: initData.endDate,
      };

      const relationFine = this.getRule('relationFine');
      relationFine.props = {
        ...relationFine.props,
        time: this.getTime(initData.beginDate, initData.endDate),
        controlIds: initData.budgetControlVos && initData.budgetControlVos.length ? initData.budgetControlVos.map((item) => item.id) : [],
      };

      // 细类
      initData.relationFine = {
        data: this.getDetail(initData, true),
        fileList: [],
      };

      this.setValue(initData);
    },

    async init() {
      const { row } = this.formConfig;
      const data = await request.get('/tpm/tpmActController/query', { id: row.actId || row.id }).then((res) => (res.success ? res.result : []));
      // 处理时间
      data.actTime = [`${data.beginDate} ${data.beginDateSecond}`, `${data.endDate} ${data.endDateSecond}`];
      // 费用预算编码
      data.budget = {
        data: data.editBudgetVos,
      };
      const budgetCode = this.getRule('budget');
      budgetCode.props = {
        ...budgetCode.props,
        startTime: data.beginDate,
        endTime: data.endDate,
      };

      const relationFine = this.getRule('relationFine');
      relationFine.props = {
        ...relationFine.props,
        time: this.getTime(data.beginDate, data.endDate),
        controlIds: data.editBudgetVos && data.editBudgetVos.length ? data.editBudgetVos.map((item) => item.id) : [],
      };

      // 文件处理
      let _fileList = [];
      if (data.fileList && data.fileList.length) {
        _fileList = this.formateData(data.fileList, ['url', 'fileName'], ['fileAddress', 'objectName'], {}, false, false);
      }

      // 细类
      data.relationFine = {
        data: this.getDetail(data),
        fileList: _fileList,
      };

      data.detailVos = data.detailVos.map((v) => ({
        ...v,
        giftProductList: v.giftProductList || [],
        normalProductList: v.normalProductList || [],
        replenishmentProductList: v.replenishmentProductList || [],
        giftCodes: v.giftProductList ? v.giftProductList.map((sub) => sub.productCode) : [],
        productCodes: v.normalProductList ? v.normalProductList.map((sub) => sub.productCode) : [],
        replenishmentProductCodes: v.replenishmentProductList ? v.replenishmentProductList.map((sub) => sub.productCode) : [],
      }));

      this.fData = data;
      this.setValue(data);
    },
  },
};
