var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "edit-table" },
    [
      _c("SelectConfig", {
        ref: "selectConfig",
        on: { onGetSelect: _vm.onGetSelect },
      }),
      _c(
        "vxe-button",
        {
          staticStyle: { "margin-bottom": "10px" },
          on: { click: _vm.addActivity },
        },
        [_vm._v("活动类型")]
      ),
      _vm._l(_vm.detailVos, function (item, k) {
        return _c(
          "div",
          { key: k, staticClass: "list-wrap" },
          [
            _c(
              "el-form",
              {
                ref: "detailForm",
                refInFor: true,
                staticClass: "demo-ruleForm",
                attrs: {
                  model: _vm.detailForm,
                  rules: _vm.detailRule,
                  "label-width": "100px",
                },
              },
              [
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "活动大类", prop: "name" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { placeholder: "请选择活动大类" },
                                on: {
                                  change: function ($event) {
                                    return _vm.getFineList($event, k)
                                  },
                                },
                                model: {
                                  value: item.categoriesCode,
                                  callback: function ($$v) {
                                    _vm.$set(item, "categoriesCode", $$v)
                                  },
                                  expression: "item.categoriesCode",
                                },
                              },
                              _vm._l(_vm.categoryList, function (item) {
                                return _c("el-option", {
                                  key: item.categoriesCode,
                                  attrs: {
                                    label: item.categoriesName,
                                    value: item.categoriesCode,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "活动细类", prop: "name" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { placeholder: "请选择活动细类" },
                                on: {
                                  change: function ($event) {
                                    return _vm.changSubcategory($event, k)
                                  },
                                },
                                model: {
                                  value: item.fineCode,
                                  callback: function ($$v) {
                                    _vm.$set(item, "fineCode", $$v)
                                  },
                                  expression: "item.fineCode",
                                },
                              },
                              _vm._l(item.subcategory, function (sItem) {
                                return _c("el-option", {
                                  key: sItem.fineCode,
                                  attrs: {
                                    label: sItem.fineName,
                                    value: sItem.fineCode,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      {
                        staticStyle: {
                          display: "flex",
                          "justify-content": "flex-end",
                          "align-items": "center",
                        },
                        attrs: { span: 8 },
                      },
                      [
                        _c("el-button", {
                          attrs: {
                            type: "danger",
                            icon: "el-icon-delete",
                            circle: "",
                            size: "mini",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.deleteActivity(k)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "vxe-button",
                  {
                    staticStyle: { "margin-bottom": "10px" },
                    on: {
                      click: function ($event) {
                        return _vm.addDetail(k, item)
                      },
                    },
                  },
                  [_vm._v("新增明细")]
                ),
                _c(
                  "el-tabs",
                  {
                    attrs: { type: "card", closable: "" },
                    on: {
                      "tab-remove": function ($event) {
                        return _vm.removeTab($event, k)
                      },
                    },
                    model: {
                      value: item.editableTabsValue,
                      callback: function ($$v) {
                        _vm.$set(item, "editableTabsValue", $$v)
                      },
                      expression: "item.editableTabsValue",
                    },
                  },
                  _vm._l(item.activityInfo, function (act, j) {
                    return _c(
                      "el-tab-pane",
                      {
                        key: act.tabValue,
                        attrs: {
                          label: "活动明细表单" + (j + 1),
                          name: act.tabValue,
                        },
                      },
                      [
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "关联预算",
                                      prop: "feeBudgetCode",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          placeholder: "请选择关联预算",
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.changeFeeBudget(
                                              $event,
                                              k,
                                              j
                                            )
                                          },
                                        },
                                        model: {
                                          value: act.ext61,
                                          callback: function ($$v) {
                                            _vm.$set(act, "ext61", $$v)
                                          },
                                          expression: "act.ext61",
                                        },
                                      },
                                      _vm._l(
                                        _vm.budgetList,
                                        function (item, bIndex) {
                                          return _c("el-option", {
                                            key: bIndex,
                                            attrs: {
                                              label: item.feeBudgetCodes,
                                              value: item.feeBudgetCodes,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: { label: "公司主体", prop: "ext3" },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        disabled: "",
                                        placeholder: "公司主体",
                                      },
                                      model: {
                                        value: act.ext3,
                                        callback: function ($$v) {
                                          _vm.$set(act, "ext3", $$v)
                                        },
                                        expression: "act.ext3",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: { label: "预算科目", prop: "ext19" },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        disabled: "",
                                        placeholder: "预算科目",
                                      },
                                      model: {
                                        value: act.ext19,
                                        callback: function ($$v) {
                                          _vm.$set(act, "ext19", $$v)
                                        },
                                        expression: "act.ext19",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "活动明细名称",
                                      prop: "ext1",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: { placeholder: "活动明细名称" },
                                      model: {
                                        value: act.ext1,
                                        callback: function ($$v) {
                                          _vm.$set(act, "ext1", $$v)
                                        },
                                        expression: "act.ext1",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "活动明细编码",
                                      prop: "actDetailCode",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        disabled: "",
                                        placeholder: "活动明细编码",
                                      },
                                      model: {
                                        value: act.actDetailCode,
                                        callback: function ($$v) {
                                          _vm.$set(act, "actDetailCode", $$v)
                                        },
                                        expression: "act.actDetailCode",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "活动执行时间",
                                      prop: "name",
                                    },
                                  },
                                  [
                                    _c("el-date-picker", {
                                      attrs: {
                                        "value-format": "yyyy-MM-dd HH:mm:ss",
                                        type: "datetimerange",
                                        "range-separator": "至",
                                        "start-placeholder": "开始日期",
                                        "end-placeholder": "结束日期",
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.changeExecuteTime(
                                            $event,
                                            k,
                                            j
                                          )
                                        },
                                      },
                                      model: {
                                        value: act.executeTime,
                                        callback: function ($$v) {
                                          _vm.$set(act, "executeTime", $$v)
                                        },
                                        expression: "act.executeTime",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "受益经销商",
                                      prop: "ext7",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.openHandleClick(
                                              "customer",
                                              "customer_list",
                                              "customerCode",
                                              k,
                                              j
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            disabled: act.ext4,
                                            placeholder: "受益经销商",
                                          },
                                          model: {
                                            value: act.ext7,
                                            callback: function ($$v) {
                                              _vm.$set(act, "ext7", $$v)
                                            },
                                            expression: "act.ext7",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "经销商组织",
                                      prop: "ext67",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        disabled: "",
                                        placeholder: "经销商组织",
                                      },
                                      model: {
                                        value: act.ext67,
                                        callback: function ($$v) {
                                          _vm.$set(act, "ext67", $$v)
                                        },
                                        expression: "act.ext67",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: { label: "受益组织", prop: "ext5" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.openHandleClick(
                                              "org",
                                              "org_list",
                                              "orgCode",
                                              k,
                                              j
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            disabled: act.ext4,
                                            placeholder: "受益组织",
                                          },
                                          model: {
                                            value: act.ext5,
                                            callback: function ($$v) {
                                              _vm.$set(act, "ext5", $$v)
                                            },
                                            expression: "act.ext5",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "责任组织",
                                      prop: "createOrgName",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        disabled: "",
                                        placeholder: "责任组织",
                                      },
                                      model: {
                                        value: act.createOrgName,
                                        callback: function ($$v) {
                                          _vm.$set(act, "createOrgName", $$v)
                                        },
                                        expression: "act.createOrgName",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "负责人", prop: "ext40" } },
                                  [
                                    _c(
                                      "div",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.openHandleClick(
                                              "person",
                                              "person_list",
                                              "userName",
                                              k,
                                              j
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: { placeholder: "负责人" },
                                          model: {
                                            value: act.ext40,
                                            callback: function ($$v) {
                                              _vm.$set(act, "ext40", $$v)
                                            },
                                            expression: "act.ext40",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            item.ext68 == "act-detail-template-2"
                              ? _c(
                                  "el-col",
                                  { attrs: { span: 8 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: { label: "周期", prop: "ext38" },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            attrs: { placeholder: "周期" },
                                            model: {
                                              value: act.ext38,
                                              callback: function ($$v) {
                                                _vm.$set(act, "ext38", $$v)
                                              },
                                              expression: "act.ext38",
                                            },
                                          },
                                          _vm._l(
                                            _vm.cycleList,
                                            function (pItem) {
                                              return _c("el-option", {
                                                key: pItem.dictValue,
                                                attrs: {
                                                  label: pItem.dictKey,
                                                  value: pItem.dictValue,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            item.ext68 == "act-detail-template-2"
                              ? _c(
                                  "el-col",
                                  { attrs: { span: 8 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: { label: "产品", prop: "ext5" },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return _vm.openHandleClick(
                                                  "displayProduct",
                                                  "product_list",
                                                  "materialCode",
                                                  k,
                                                  j
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: { placeholder: "产品" },
                                              model: {
                                                value: act.ext32,
                                                callback: function ($$v) {
                                                  _vm.$set(act, "ext32", $$v)
                                                },
                                                expression: "act.ext32",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            item.ext68 == "act-detail-template-2"
                              ? _c(
                                  "el-col",
                                  { attrs: { span: 8 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "产品系列",
                                          prop: "ext34",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            disabled: "",
                                            placeholder: "产品系列",
                                          },
                                          model: {
                                            value: act.ext34,
                                            callback: function ($$v) {
                                              _vm.$set(act, "ext34", $$v)
                                            },
                                            expression: "act.ext34",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            item.ext68 == "act-detail-template-2"
                              ? _c(
                                  "el-col",
                                  { attrs: { span: 8 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: { label: "阶梯", prop: "ext35" },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            attrs: {
                                              placeholder: "阶梯",
                                              filterable: "",
                                              remote: "",
                                              "remote-method":
                                                _vm.getDisplayLadder,
                                            },
                                            model: {
                                              value: act.ext35,
                                              callback: function ($$v) {
                                                _vm.$set(act, "ext35", $$v)
                                              },
                                              expression: "act.ext35",
                                            },
                                          },
                                          _vm._l(
                                            _vm.diplayLadderList,
                                            function (pItem) {
                                              return _c("el-option", {
                                                key: pItem.ladderCode,
                                                attrs: {
                                                  label: pItem.ladderName,
                                                  value: pItem.ladderCode,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            item.ext68 == "act-detail-template-2"
                              ? _c(
                                  "el-col",
                                  { attrs: { span: 8 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: { label: "家数", prop: "ext36" },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: { placeholder: "家数" },
                                          model: {
                                            value: act.ext36,
                                            callback: function ($$v) {
                                              _vm.$set(act, "ext36", $$v)
                                            },
                                            expression: "act.ext36",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            item.ext68 == "act-detail-template-3"
                              ? _c(
                                  "el-col",
                                  { attrs: { span: 8 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: { label: "周期", prop: "ext38" },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            attrs: { placeholder: "周期" },
                                            model: {
                                              value: act.ext38,
                                              callback: function ($$v) {
                                                _vm.$set(act, "ext38", $$v)
                                              },
                                              expression: "act.ext38",
                                            },
                                          },
                                          _vm._l(
                                            _vm.cycleList,
                                            function (pItem) {
                                              return _c("el-option", {
                                                key: pItem.dictValue,
                                                attrs: {
                                                  label: pItem.dictKey,
                                                  value: pItem.dictValue,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            item.ext68 == "act-detail-template-3"
                              ? _c(
                                  "el-col",
                                  { attrs: { span: 8 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: { label: "产品", prop: "ext5" },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return _vm.openHandleClick(
                                                  "displayProduct",
                                                  "product_list",
                                                  "materialCode",
                                                  k,
                                                  j
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: { placeholder: "产品" },
                                              model: {
                                                value: act.ext32,
                                                callback: function ($$v) {
                                                  _vm.$set(act, "ext32", $$v)
                                                },
                                                expression: "act.ext32",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            item.ext68 == "act-detail-template-3"
                              ? _c(
                                  "el-col",
                                  { attrs: { span: 8 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "产品系列",
                                          prop: "ext34",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: { placeholder: "产品系列" },
                                          model: {
                                            value: act.ext34,
                                            callback: function ($$v) {
                                              _vm.$set(act, "ext34", $$v)
                                            },
                                            expression: "act.ext34",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            item.ext68 == "act-detail-template-3"
                              ? _c(
                                  "el-col",
                                  { attrs: { span: 8 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: { label: "阶梯", prop: "ext35" },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            attrs: {
                                              placeholder: "阶梯",
                                              filterable: "",
                                              remote: "",
                                              "remote-method":
                                                _vm.getPackageLadder,
                                            },
                                            model: {
                                              value: act.ext35,
                                              callback: function ($$v) {
                                                _vm.$set(act, "ext35", $$v)
                                              },
                                              expression: "act.ext35",
                                            },
                                          },
                                          _vm._l(
                                            _vm.packageLadderList,
                                            function (pItem) {
                                              return _c("el-option", {
                                                key: pItem.ladderCode,
                                                attrs: {
                                                  label: pItem.ladderName,
                                                  value: pItem.ladderCode,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            item.ext68 == "act-detail-template-3"
                              ? _c(
                                  "el-col",
                                  { attrs: { span: 8 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: { label: "家数", prop: "ext36" },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: { placeholder: "家数" },
                                          model: {
                                            value: act.ext36,
                                            callback: function ($$v) {
                                              _vm.$set(act, "ext36", $$v)
                                            },
                                            expression: "act.ext36",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: { label: "执行次数", prop: "ext37" },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: { placeholder: "执行次数" },
                                      model: {
                                        value: act.ext37,
                                        callback: function ($$v) {
                                          _vm.$set(act, "ext37", $$v)
                                        },
                                        expression: "act.ext37",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "申请金额",
                                      prop: "applyAmount",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: { placeholder: "申请金额" },
                                      model: {
                                        value: act.applyAmount,
                                        callback: function ($$v) {
                                          _vm.$set(act, "applyAmount", $$v)
                                        },
                                        expression: "act.applyAmount",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "支付方式",
                                      prop: "payType",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          placeholder: "请选择支付方式",
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.changePayType(
                                              $event,
                                              k,
                                              j,
                                              item.payTypes
                                            )
                                          },
                                        },
                                        model: {
                                          value: act.payType,
                                          callback: function ($$v) {
                                            _vm.$set(act, "payType", $$v)
                                          },
                                          expression: "act.payType",
                                        },
                                      },
                                      _vm._l(item.payTypes, function (pItem) {
                                        return _c("el-option", {
                                          key: pItem.dictValue,
                                          attrs: {
                                            label: pItem.dictKey,
                                            value: pItem.dictValue,
                                          },
                                        })
                                      }),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            act.payType == "4"
                              ? _c(
                                  "el-col",
                                  { attrs: { span: 8 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "兑付产品",
                                          prop: "ext9",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return _vm.openHandleClick(
                                                  "product",
                                                  "product_list",
                                                  "materialCode",
                                                  k,
                                                  j
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                placeholder: "兑付产品",
                                              },
                                              model: {
                                                value: act.ext9,
                                                callback: function ($$v) {
                                                  _vm.$set(act, "ext9", $$v)
                                                },
                                                expression: "act.ext9",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            act.payType == "4"
                              ? _c(
                                  "el-col",
                                  { attrs: { span: 8 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: { label: "单价", prop: "ext21" },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            disabled: "",
                                            placeholder: "单价",
                                          },
                                          model: {
                                            value: act.ext21,
                                            callback: function ($$v) {
                                              _vm.$set(act, "ext21", $$v)
                                            },
                                            expression: "act.ext21",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "el-col",
                              { attrs: { span: 24 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "活动执行细则",
                                      prop: "ext13",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        type: "textarea",
                                        autosize: "",
                                        placeholder: "活动执行细则",
                                      },
                                      model: {
                                        value: act.ext13,
                                        callback: function ($$v) {
                                          _vm.$set(act, "ext13", $$v)
                                        },
                                        expression: "act.ext13",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }