import Form, { formCreate } from '@/found/components/form';
import request from '@/found/utils/request';

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      formFunctionCode: 'submit_approve',
      buttonText: {
        submit: '确定关闭',
      },
    };
  },

  created() {},

  methods: {
    // 设置rule
    setRule(item) {
      const v = item;
      if (v.field === 'workFlowKey') {
        v.options = this.formConfig.progressList;
        v.on = {
          change: (e) => {
            if (e) {
              this.setValue({
                workFlowKey: e,
              });
            } else {
              this.setValue({
                workFlowKey: null,
                workFlowName: null,
              });
            }
          },
          getLabel: (e) => {
            this.setValue({
              workFlowName: e,
            });
          },
        };
      }
      return v;
    },

    formComplete() {},

    // 提交
    async submit() {
      const params = this.getFormData();
      if (!params) return false;
      const url = '/tpm/tpmActExt/reconsiderApproval';
      params.actType = 'project';
      params.id = this.formConfig.row.actId || this.formConfig.row.id;
      const { success } = await request.post(url, params);
      if (success) {
        this.$message({
          type: 'success',
          message: '操作成功',
        });
        this.$emit('onClose');
        this.$emit('onGetList');
      }
    },
  },
};
